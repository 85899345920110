import React from "react"
import styles from './button.scss';

export default (props) => {
  return (
    <a className="button" href={props.to}>
      {props.children}
    </a>
  );
}
