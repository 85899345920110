import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Button from './button';

const Header = ({ siteTitle }) => (
  <header
    style={{
      padding: `1em`,
      borderBottom: '1px solid #2E2E2E'
    }}
  >
    <div style={{ maxWidth: '1140px', textAlign: 'right', margin: '0 auto' }}>
      <Button to="https://cp-a-1.castual.com">Log in</Button>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
